import { observer } from "mobx-react";
import { useState } from "react";
import { getEstimaticsCurrentProjectStore, getUserStore } from "../../../data/DataStores";
import { logDebug } from "../../../shared/logger";
import Analytics from "../../../utils/Analytics";
import Select from "react-select";

export const defaultEstimaticsStatusMap: { [k: string]: any } = {
	Received: {
		index: 0,
		text: "text-slate-600",
		bgColor: "bg-white",
		border: "border-slate-600",
	},
	"In progress": {
		index: 1,
		text: "text-blue-600",
		bgColor: "bg-sky-50",
		border: "border-blue-600",
	},
	"Waiting on information": {
		index: 2,
		text: "text-yellow-400",
		bgColor: "bg-yellow-50",
		border: "border-yellow-400",
	},
	"Under review": {
		index: 3,
		text: "text-yellow-400",
		bgColor: "bg-yellow-50",
		border: "border-yellow-400",
	},
	"Final review": {
		index: 4,
		text: "text-emerald-500",
		bgColor: "bg-emerald-50",
		border: "border-emerald-500",
	},
	Approved: {
		index: 5,
		border: "border-emerald-500",
		bgColor: "bg-emerald-50",
		text: "text-emerald-500",
	},
	Paid: {
		index: 6,
		border: "border-emerald-500",
		bgColor: "bg-emerald-50",
		text: "text-emerald-500",
	},
	Closed: {
		index: 7,
		text: "text-gray-300",
		bgColor: "bg-white",
		border: "border-gray-300",
	},
};

export function getEstimaticsStatusMapDefault() {
	const userStore = getUserStore();
	if (userStore.customSettings.status && userStore.customSettings.status.length > 0) {
		return userStore.customSettings.status[0].name;
	}
	return "Received";
}

export function getEstimaticsStatusMap() {
	const userStore = getUserStore();
	let estimaticsStatusMap = defaultEstimaticsStatusMap;
	if (userStore.customSettings.status && userStore.customSettings.status.length > 0) {
		const customStatus = userStore.customSettings.status as { name: string; color: string }[];
		estimaticsStatusMap = customStatus.reduce((acc, status, index) => {
			const color = status.color === "green" ? "emerald" : status.color;
			acc[status.name] = {
				index: index,
				text: `text-${color}-500`,
				bgColor: `bg-${color}-50`,
				border: `border-${color}-400`,
			};
			return acc;
		}, {} as { [k: string]: any });
	}
	// logDebug('EstimaticsStatusComboBox', 'getEstimaticsStatusMap', estimaticsStatusMap);
	return estimaticsStatusMap;
}

const EstimaticsStatusComboBox: React.FC<{
	allowEmpty?: boolean;
	onChange?: (v: string) => void;
	status?: string;
	disabled?: boolean;
	width?: number | string;
	height?: number | string;
	update?: boolean;
}> = observer(({ allowEmpty, height, status, disabled, width, update = true, onChange }) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const [value, setValue] = useState(status || (allowEmpty ? "" : getEstimaticsStatusMapDefault()));

	let _statusMap = getEstimaticsStatusMap();
	if (allowEmpty) {
		_statusMap = {
			"": {
				text: "text-slate-600",
				bgColor: "bg-white",
				border: "border-slate-600",
			},
			...getEstimaticsStatusMap(),
		};
	}
	function getClass(key: string) {
		let _statusMap = getEstimaticsStatusMap();
		if (!_statusMap[key]) return "";
		return _statusMap[key]!.text + " " + _statusMap[key].bgColor + " " + _statusMap[key].border;
	}
	// logDebug('EstimaticsStatusComboBox', 'render', status);
	return (
		<>
			{!disabled && (
				<Select
					styles={{
						control: (styles) => ({ ...styles, backgroundColor: "white" }),
						option: (styles, { data, isDisabled, isFocused, isSelected }) => {
							return {
								...styles,
								backgroundColor: !isFocused ? "white" : "#DEEBFF",
								color: "red",
								cursor: isDisabled ? "not-allowed" : "default",

								":active": {
									...styles[":active"],
									backgroundColor: "#2684FF",
								},
							};
						},
					}}
					onChange={(newValue) => {
						Analytics.Instance.track("EstimaticsStatusChanged", {
							status: newValue?.value ?? "",
							oldStatus: value,
						});
						setValue(newValue?.value ?? "");
						update && currentEstimateStore.setValue("status", newValue?.value ?? "", true);
						update && currentEstimateStore.setValue("dateUpdated", new Date().toISOString(), true);
						onChange && onChange(newValue?.value ?? "");
					}}
					className={`w-[${width || 160}px]`}
					value={
						value
							? {
									value: value,
									label: <span className={`w-32 ${_statusMap[value].bgColor} ${_statusMap[value].text}`}>{value}</span>,
									// color: `${_statusMap[value]?.bgColor ?? ''} ${_statusMap[value]?.text ?? ''}`
							  }
							: null
					}
					options={Object.keys(_statusMap).map((_status) => ({
						value: _status,
						label: (
							<span
								style={{ minWidth: 164, width: 164 }}
								className={` ${_statusMap[_status].bgColor} ${_statusMap[_status].text}`}
							>
								{_status}
							</span>
						),
					}))}
				/>
			)}
			{disabled && (
				<div
					// className={`flex justify-center pr-2 pl-1 overflow-x-hidden overflow-y-hidden whitespace-nowrap text-ellipsis bg-sky-50 rounded-md border ${getClass()}`}
					className={`font-['Hanken Grotesk'] text-center pt-[1px] pr-2 pl-1 overflow-x-hidden overflow-y-hidden whitespace-nowrap text-ellipsis bg-sky-50 rounded-md border ${getClass(
						status || getEstimaticsStatusMapDefault()
					)}`}
					style={{
						fontSize: "14px",
						fontWeight: "400",
						lineHeight: "19px",
						width: width || 160,
						height: height || 25,
					}}
				>
					{status || (allowEmpty ? "" : getEstimaticsStatusMapDefault())}
				</div>
			)}
		</>
	);
});

export default EstimaticsStatusComboBox;
